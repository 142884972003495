import React from 'react';
import { object } from 'prop-types';
import { allComponents, SEO, ModalPage } from '../components';
import website from '../../config/website';
import { withPreview } from 'gatsby-source-prismic';

const getTitle = ({ title }) => {
  if (title) {
    return title.text + ' | ' + website._title;
  } else {
    return website._title;
  }
};

const FAQConstructor = ({ pageContext, data }) => {
  if (data && data.prismicT13Faq) {
    let newArr = [];
    // Preview data is loaded via proxy and we need to loop through it to get it loaded
    data.prismicT13Faq.data.body.forEach(element => {
      newArr.push(element);
    });
    pageContext.names = newArr; // Inject it back into the main component array
    pageContext.pageLang = data.prismicT13Faq.data.pageLang;
    pageContext.articleTags = data.prismicT13Faq.data.articleTags;
    pageContext.title = data.prismicT13Faq.data.meta_title;
    pageContext.description = data.prismicT13Faq.data.meta_description;
    pageContext.robots = data.prismicT13Faq.data.robots;
    pageContext.newsletter = data.prismicT13Faq.data.newsletter;
    pageContext.faq_link = data.prismicT13Faq.data.faq_link;
  }

  return (
    <ModalPage modify={true} pageLang={pageContext.pageLang} newsletter={pageContext.newsletter}>
      <SEO
        title={`${getTitle(pageContext)}`}
        robots={pageContext.robots ? pageContext.robots : null}
        description={pageContext.description ? pageContext.description : null}
      />
      {pageContext.names.map((el, i) => {
        const postfix = el ? el.__typename.slice(-5) : null;
        const Component = allComponents[postfix];
        return !Component ? null : (
          <Component
            key={i}
            primary={el.primary}
            items={el.items}
            index={i + 1}
            type={pageContext.type}
            pageLang={pageContext.pageLang}
          />
        );
      })}
    </ModalPage>
  );
};

FAQConstructor.propTypes = {
  pageContext: object.isRequired
};

export default withPreview(FAQConstructor);
